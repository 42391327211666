import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';

import { AccordionItem } from '@cof/lighthouse-component-library';
import { LayoutWrapper } from '@cof/lighthouse-component-library';
import { Link } from '@cof/lighthouse-component-library';
import { Tile } from '@cof/lighthouse-component-library';

import Header from '../components/HeaderLH';
import { LayoutPDP } from '../components/LayoutPDP';
import PdpFooterLH from '../components/Pdp/PdpFooterLH';
import StickyBar from '../components/StickyBar';
import { useIntersectionObserver } from '../hooks/useIntersectionObserver';
import { appendCorrelationIdQS } from '../lib/correlationId';
import { superScript } from '../lib/utils';
import { addCheckFirstQS, appendExternalID } from '../lib/urls';

import shield from '../assets/images/shield.svg';
import creditCard from '../assets/images/credit-card.svg';
import clock from '../assets/images/clock.svg';

import creditDesktop from '../assets/images/ua-landing-credit-desktop.png';
import creditTablet from '../assets/images/ua-landing-credit-tablet.png';
import creditMobile from '../assets/images/ua-landing-credit-mobile.png';

import './getacard.scss';
import SEO from '../components/SEO/SEO';

const QuickCheckButton = ({ intl, preset }) => {
  return (
    <Link
      to={appendExternalID(
        addCheckFirstQS(
          appendCorrelationIdQS(intl.formatMessage({ id: `pages.get-a-card.header.quickcheck-form-link` })),
          'gm',
        ),
      )}
      accessibilityLabel={intl.formatMessage({
        id: `pages.get-a-card.header.button-accessbility-label`,
      })}
      isButton
      preset={preset}
      newTab
      referrerTracking
    >
      <FormattedMessage id="pages.get-a-card.header.button" />
    </Link>
  );
};

QuickCheckButton.propTypes = {
  intl: intlShape,
  preset: PropTypes.string,
};

const QuickcheckCardTiles = () => {
  return (
    <div className="quickcheck-card-tiles-container">
      <h2>
        <FormattedMessage id="pages.get-a-card.quickcheck-card-tiles.title" />
      </h2>
      <div className="quickcheck-content">
        <div className="quickcheck-steps-card-tiles">
          <Tile className="quickcheck-step-tile">
            <img src={shield} alt="" />
            <h3>
              <FormattedMessage id="pages.get-a-card.quickcheck-card-tiles.card-tiles.tile1.heading" />
            </h3>
            <p>
              <FormattedMessage id="pages.get-a-card.quickcheck-card-tiles.card-tiles.tile1.description" />
            </p>
          </Tile>
          <Tile className="quickcheck-step-tile">
            <img src={creditCard} alt="" />
            <h3>
              <FormattedMessage
                id="pages.get-a-card.quickcheck-card-tiles.card-tiles.tile2.heading"
                values={{ star: superScript('star') }}
              />
            </h3>
            <p data-testid="quickcheck-tile-two-description">
              <FormattedMessage
                id="pages.get-a-card.quickcheck-card-tiles.card-tiles.tile2.description"
                values={{ star: superScript('star') }}
              />
            </p>
          </Tile>
          <Tile className="quickcheck-step-tile">
            <img src={clock} alt="" />
            <h3>
              <FormattedMessage id="pages.get-a-card.quickcheck-card-tiles.card-tiles.tile3.heading" />
            </h3>
            <p data-testid="quickcheck-tile-three-description">
              <FormattedMessage id="pages.get-a-card.quickcheck-card-tiles.card-tiles.tile3.description" />
            </p>
          </Tile>
        </div>
      </div>
    </div>
  );
};

const Credit = () => {
  return (
    <div className="credit-info-container">
      <div className="credit-image-box">
        <img src={creditDesktop} alt="" className="credit-desktop" />
        <img src={creditTablet} alt="" className="credit-tablet" />
        <img src={creditMobile} alt="" className="credit-mobile" />
      </div>
      <div className="credit-content">
        <h2>
          <FormattedMessage id="pages.get-a-card.credit.heading" />
        </h2>
        <p data-testid="credit-description">
          <FormattedMessage id="pages.get-a-card.credit.description" values={{ star: superScript('star') }} />
        </p>
      </div>
    </div>
  );
};

const LegalDisclaimer = ({ intl }) => {
  return (
    <section id="legal-disclaimer-content">
      <AccordionItem
        title={<h2>{intl.formatMessage({ id: `navfooter.legal.legal` })}</h2>}
        id="legal-info-accordion"
        onFocus={() => {}}
        onBlur={() => {}}
        isIndependent
      >
        <div>
          <p className="quickcheck-preapproval" data-testid="legal-accordion-heading-one">
            <FormattedMessage id="pages.pdp-gm.legal-disclaimer.info.p1" values={{ sup1: superScript('star') }} />
          </p>
          <ol type="a">
            <li>
              <FormattedMessage id="pages.pdp-gm.legal-disclaimer.info.p1-1" />
            </li>
            <li>
              <FormattedMessage id="pages.pdp-gm.legal-disclaimer.info.p1-2" />
            </li>
            <li>
              <FormattedMessage id="pages.pdp-gm.legal-disclaimer.info.p1-3" />
            </li>
            <li>
              <FormattedMessage id="pages.pdp-gm.legal-disclaimer.info.p1-4" />
            </li>
            <li>
              <FormattedMessage id="pages.pdp-gm.legal-disclaimer.info.p1-5" />
            </li>
          </ol>
          <p data-testid="legal-accordion-subheading-six">
            <FormattedMessage id="pages.pdp-gm.legal-disclaimer.info.p1-6" values={{ md: <sup>MD</sup> }} />
          </p>
        </div>
      </AccordionItem>
    </section>
  );
};

LegalDisclaimer.propTypes = {
  intl: intlShape,
};

const GetACard = injectIntl(({ intl }) => {
  const GetACardHeader = () => {
    return <Header intl={intl} />;
  };
  const GetACardFooter = () => {
    return <PdpFooterLH intl={intl} hidePromo={true} />;
  };
  const supportsIntersectionObserver =
    typeof window !== 'undefined' ? window.IntersectionObserver || 'IntersectionObserver' in window : undefined;
  const componentRef = React.useRef(null);
  const entry = useIntersectionObserver(componentRef, {});
  const isVisible = !!entry?.isIntersecting;
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setIsAnimating(true);
    }
  }, [isVisible]);

  return (
    <LayoutPDP containerClass="get-a-card-container" CustomHeader={GetACardHeader} CustomFooter={GetACardFooter}>
      <LayoutWrapper className="ua-header-layout-wrapper">
        <div className="ua-header-container">
          <h1>
            <FormattedMessage
              id="pages.get-a-card.header.title"
              values={{ star: superScript('star'), MC: <sup>MC</sup> }}
            />
          </h1>
          <div ref={componentRef}>
            <QuickCheckButton intl={intl} preset={'primary-alt'} />
          </div>
        </div>
      </LayoutWrapper>
      <LayoutWrapper className="quickcheck-card-tiles-layout-wrapper pdp-gray-background">
        <QuickcheckCardTiles />
      </LayoutWrapper>
      <LayoutWrapper className="credit-wrapper-get-a-card pdp-gray-background">
        <Credit />
      </LayoutWrapper>
      <LayoutWrapper className={`legal-disclaimer-layout-wrapper-get-a-card extra-space-bottom pdp-gray-background`}>
        <LegalDisclaimer intl={intl} />
      </LayoutWrapper>
      {(!supportsIntersectionObserver || isAnimating) && (
        <StickyBar
          className={`${!isVisible ? 'stickybar-visible' : 'stickybar-hidden'}`}
          contentPosition="center"
          isVerticalDisplayinSmallScreen={false}
          stickybarPosition="bottom"
        >
          <QuickCheckButton intl={intl} preset={'primary'} />
        </StickyBar>
      )}
    </LayoutPDP>
  );
});

export default GetACard;

/* eslint-disable react/prop-types */
export const Head = ({ pageContext }) => (
  <SEO
    titleKey="pages.get-a-card.seo.page-title"
    descriptionKey="pages.get-a-card.seo.page-desc"
    intl={pageContext.intl}
  />
);
/* eslint-enable react/prop-types */
