import React from 'react';
import PropTypes from 'prop-types';

import './StickyBar.scss';

/**
 * Use this molecule when you want to setup a stickybar at the bottom or top of a page.
 * This molecule will stay at a fixed postion, either at the bottom or top of a page.
 * Propties passed to this molecule can be `stickybarPosition` (set stickybar postion, eg. bottom and top),
 * `contentPosition` (justify content postion inside stickybar, eg. left, right, center, space-around and space-between),
 * `children` (the content that will be displayed in the stickybar) and `isVerticalDisplayinSmallScreen` (vertical or horizental
 * layout of the content in small screen, and breakpoint in medium screen). The maximum height for the stickybar is 115px and the
 * maximum height for the image inside the stickybar is 83px.
 */

const StickyBar = ({ children, className, contentPosition, stickybarPosition, isVerticalDisplayinSmallScreen }) => {
  const smallScreenLayout = isVerticalDisplayinSmallScreen ? 'column-display' : 'row-display';
  return (
    <div
      className={`stickybar-wrapper stickybar-position-${stickybarPosition} stickybar-content-${contentPosition} stickybar-content-${smallScreenLayout} ${
        className ? className : ''
      }`}
      data-testid="sticky-bar-wrapper"
    >
      {children}
    </div>
  );
};

StickyBar.propTypes = {
  /** Content of the stickybar */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
  /** Additional styling */
  className: PropTypes.string,
  /** Stickybar justify content postion*/
  contentPosition: PropTypes.oneOf(['center', 'left', 'right', 'space-around', 'space-between']),
  /** Stickybar postion (bottom vs top of page)*/
  stickybarPosition: PropTypes.oneOf(['top', 'bottom']),
  /** Justify content layout in small screen*/
  isVerticalDisplayinSmallScreen: PropTypes.bool,
};

StickyBar.defaultProps = {
  contentPosition: 'center',
  stickybarPosition: 'bottom',
  isVerticalDisplayinSmallScreen: false,
};

export default StickyBar;
